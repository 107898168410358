import Amplify from "aws-amplify";
import awsconfig from '../aws-exports'

Amplify.configure(awsconfig)

function Login() {

  return (<>
    <section className="container">
      <div className="row">
        <div className="col">
          <label>Email Address</label>
          <input type="email" placeholder="yourname@example.com"></input>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <label>Password</label>
          <input type="password" placeholder="yourname@example.com"></input>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <button className="outline hover">Login</button>
        </div>
      </div>
    </section>
  </>)

}

export default Login
