import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from 'react-router-dom'
import SignUp from './pages/SignUp'
import Login from './pages/Login'
import lightbulb from './idea.png'

function App() {
  return (
    <Router>
      <div className="App">
        <div className="nav horizontal-align">
          <div className="nav-item">
            <Link to="/">MyMarket.guru</Link>
          </div>
          <div className="nav-item">
            <Link to="/login">Login</Link>
          </div>
          <div className="nav-item">
            <Link to="/signup">Create Account</Link>
          </div>
        </div>
        <Switch>
          <Route path="/signup">
            <SignUp />
          </Route>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/">
            <section className="hero container center">
              <div className="row">
                <h3>My Market Guru</h3>
              </div>
            </section>
            <section className="hero container center">
              <img src={lightbulb} width={200} alt="lightbulb" />
            </section>
            <section className="hero container center">
              <div className="row">
                <h5>Insights for South African e-Commerce stores</h5>
              </div>
            </section>
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
